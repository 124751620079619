import { DonationIntervals, PaymentMethods } from '@betterplace/api-graphql-types'
import { cappedString64, requiredCappedString64 } from '@/schema/sanitizedText'
import {
  htmlColorString,
  htmlColorStringWithoutTransparent,
  numericString,
  receiverTypePathParam,
  slug,
} from '@/schema'
import { report } from '@/errorReporting'
import { z } from 'zod'
// eslint-disable-next-line import/exports-last
export const UTM_PARAM_LENGTH = 64

// eslint-disable-next-line import/exports-last
export const donationAmountSchema = z.coerce
  .number()
  .transform((val) => val * 100)
  .optional()
// eslint-disable-next-line import/exports-last
export const utmParamSchema = z.preprocess(
  (arg) => (typeof arg === 'string' ? arg.trim().slice(0, UTM_PARAM_LENGTH) : arg),
  cappedString64
)

// eslint-disable-next-line import/exports-last
export const bottomLogoOptions = z.union([z.literal('white'), z.literal('disabled'), z.coerce.boolean()]).optional()

const getDonationFormConfigSchema = z.object({
  slug,
  receiverType: receiverTypePathParam,
  channel: requiredCappedString64,
  background_color: htmlColorString.optional(),
  bottom_logo: bottomLogoOptions.catch(false),
  color: htmlColorStringWithoutTransparent.optional(),
  client_reference: cappedString64,
  default_interval: z.preprocess(
    (val) => (typeof val === 'string' ? val.toUpperCase() : val),
    z
      .enum([DonationIntervals.Monthly, DonationIntervals.Single, DonationIntervals.Yearly])
      .optional()
      .catch(({ error }) => {
        report(error)
        return DonationIntervals.Single
      })
  ),
  default_payment_method: z.preprocess(
    (val) => (typeof val === 'string' ? val.toUpperCase() : val),
    z
      .enum([
        PaymentMethods.DirectDeposit,
        PaymentMethods.Paypal,
        PaymentMethods.StripeSepaDebit,
        PaymentMethods.StripeCc,
        PaymentMethods.DummyPayment,
      ])
      .optional()
      .catch(({ error }) => {
        report(error)
        return PaymentMethods.Paypal
      })
  ),
  donation_amount: donationAmountSchema,
  need_id: numericString.optional(),
  utm_campaign: utmParamSchema,
  utm_medium: utmParamSchema,
  utm_source: utmParamSchema,
  tracking_via: utmParamSchema,
})
export type GetDonationFormConfigInput = z.input<typeof getDonationFormConfigSchema>

export type DonationFormSearchParams = Omit<
  z.output<typeof getDonationFormConfigSchema>,
  'slug' | 'receiverType' | 'channel'
>
export default getDonationFormConfigSchema
