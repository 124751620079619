import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import FormCheckbox from '@/form/FormCheckbox'
import I18nHtml from '@/components/I18nHtml'
import styles from './AddressFieldToggle.module.css'
import { Heading, LayoutStack } from '@betterplace/design-system/client'
import { useDonationFormContext, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useEffect } from 'react'
import { useTranslations } from 'next-intl'

function AddressFieldToggle({ children }: { children: React.ReactNode }) {
  const { setValue } = useDonationFormContext()
  const [receiptDesired] = useDonationFormValues(['receipt_desired'])
  const t = useTranslations('nextjs.donate')

  useEffect(() => {
    if (receiptDesired) return
    setValue('street', '', { shouldValidate: true })
    setValue('zip', '', { shouldValidate: true })
    setValue('city', '', { shouldValidate: true })
  }, [receiptDesired, setValue])
  return (
    <>
      <Heading level="h200" className={styles.headingMargin}>
        {t('donation_receipt')}
      </Heading>
      <LayoutStack space="200">
        <DonationFormField name="receipt_desired" id="receiveReceipt" as={FormCheckbox}>
          <I18nHtml i18nKey="nextjs.donate.receipt_desired_html" />
        </DonationFormField>
        {receiptDesired && children}
      </LayoutStack>
    </>
  )
}
export default AddressFieldToggle
