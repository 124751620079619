import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import DonationFormRequiredField from '@/donationForm/_dependencies/components/shared/DonationFormRequiredField'
import { useMemo } from 'react'

function useRequiredComponent(required?: boolean) {
  const FieldComponent = useMemo(() => (required ? DonationFormRequiredField : DonationFormField), [required])
  return FieldComponent
}

export default useRequiredComponent
