'use client'

import TextLink from '@/components/TextLink'
import { FooterCompact } from '@betterplace/design-system/page-parts'
import { RailsRoutes } from '@/helpers/utils'
import { useTranslations } from 'next-intl'
import type { Maybe } from '@/types'

function Footer({ ultimatelySubjectToLevy }: { ultimatelySubjectToLevy: Maybe<boolean> }) {
  const t = useTranslations('nextjs')
  return (
    <FooterCompact
      Link={TextLink}
      text={{
        providerInfo: t('footer.provider_info'),
        links: [
          { href: RailsRoutes.imprint({}), children: t('footer.impressum_label'), external: true },
          ...(ultimatelySubjectToLevy
            ? [
                {
                  href: t('external_links.transaction_cost.url'),
                  children: t('external_links.transaction_cost.content'),
                  external: true,
                  className: 'larger-click-area',
                },
              ]
            : []),
        ],
      }}
    />
  )
}

export default Footer
