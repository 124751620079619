import styles from './FiscalYearChangeNotice.module.css'
import { PaymentMethodProps } from '../types'
import { PaymentMethods } from '@betterplace/api-graphql-types/enums'
import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'

function FiscalYearChangeNotice({ paymentMethod }: PaymentMethodProps) {
  const t = useTranslations('nextjs.donate')
  const [isNearFiscalYearChange, setIsNearFiscalYearChange] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    if (typeof window === 'undefined') return
    const urlParams = new URLSearchParams(window.location.search)
    // Use <DonationFormUrl>?fake-date=2021-12-31&default_payment_method=direct_deposit for testing
    const now = urlParams.has('fake-date') ? new Date(urlParams.get('fake-date') ?? '') : new Date()
    // Remember, in JS the first month is 0, this is December
    setIsNearFiscalYearChange(now.getMonth() === 11 && now.getDate() > 26)
    setYear(now.getFullYear())
  }, [])

  const needsFiscalYearChangeNotice =
    isNearFiscalYearChange && [PaymentMethods.DirectDeposit, PaymentMethods.StripeSepaDebit].includes(paymentMethod)

  if (!needsFiscalYearChangeNotice) return null

  return (
    <div className={styles.fiscalYearChangeNotice}>
      {t('fiscal_year_change_notice', {
        old_year: year,
        new_year: year + 1,
      })}
    </div>
  )
}

export default FiscalYearChangeNotice
