import DonationAmountField, { AMOUNT_FIELD_NAME } from '../DonationAmountField/DonationAmountField'
import analytics, { getDonationFormType } from '@betterplace/product-analytics'
import styles from './AmountSelection.module.css'
import useLocale from '@/i18n/useLocale'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Fieldset, Heading, LayoutStack, ToggleButton } from '@betterplace/design-system/client'
import { formatCents } from '@betterplace/utils'
import { useDonationFormConfig, useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useIsMounted } from '@/helpers/hooks'
import { useTranslations } from 'next-intl'
// TODO: get these from some config
const predefinedAmountsInCents = [2500, 5000, 10000, 20000]
function AmountSelection() {
  const t = useTranslations('nextjs')
  const locale = useLocale()
  const {
    formState: { errors },
    control,
    getValues,
  } = useDonationFormContext()
  const { receiverId, receiverType, channel, showCodonation, defaultDonationCents } = useDonationFormConfig()

  const [currentValue, setCurrentValue] = useState<number>(getValues(AMOUNT_FIELD_NAME))
  const previousValue = useRef<number>(currentValue)
  const [customAmountEnabled, enableCustomAmount] = useState<boolean>(
    !predefinedAmountsInCents.includes(defaultDonationCents)
  )
  const mounted = useIsMounted()
  useEffect(() => {
    if (currentValue === previousValue.current) return

    analytics.donationAmountChanged({
      id: receiverId,
      receiver_type: receiverType,
      donation_form_type: getDonationFormType({
        receiverId,
        receiverType,
        channel,
        showCodonation,
      }),
      previous_donation_amount: previousValue.current,
      new_donation_amount: currentValue,
      selection_type: customAmountEnabled ? 'donation_own_amount' : 'donation_amount_button',
    })

    previousValue.current = currentValue
  }, [channel, currentValue, customAmountEnabled, receiverId, receiverType, showCodonation])

  return (
    <Fieldset
      legend={
        <Heading level="h200" className={styles.heading}>
          {t('donate.attributes.amount')}
        </Heading>
      }
      error={errors[AMOUNT_FIELD_NAME]?.message}
      errorDisplay="text"
      layoutProps={{
        alignment: 'justified',
        space: '100',
      }}
    >
      <Controller
        control={control}
        name={AMOUNT_FIELD_NAME}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              {predefinedAmountsInCents.map((amountInCents) => {
                const label = formatCents(locale, amountInCents)
                return (
                  <ToggleButton
                    key={amountInCents}
                    name={AMOUNT_FIELD_NAME}
                    type="radio"
                    value={amountInCents}
                    checked={amountInCents === value && !customAmountEnabled}
                    className={styles.radio}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onChange(Number((event.target as HTMLInputElement)?.value))
                      enableCustomAmount(false)
                      setCurrentValue(getValues(AMOUNT_FIELD_NAME))
                    }}
                    fullWidth="mobile-only"
                  >
                    {label}
                  </ToggleButton>
                )
              })}
            </>
          )
        }}
      />

      <LayoutStack>
        <Controller
          control={control}
          name="_custom_amount"
          render={({ field: { onChange } }) => {
            return (
              <ToggleButton
                className={styles.button}
                type="checkbox"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChange(event)
                  enableCustomAmount(true)
                  // TODO: maybe focus the input field
                }}
                disabled_={!mounted}
                checked={customAmountEnabled}
              >
                {t('donate.amount_selection.custom_amount')}
              </ToggleButton>
            )
          }}
        />

        {customAmountEnabled && (
          <Controller
            control={control}
            name={AMOUNT_FIELD_NAME}
            render={() => {
              return (
                <div className={styles.inputWrapper}>
                  <DonationAmountField
                    className={styles.input}
                    hideError={true}
                    required={true}
                    onBlur={() => {
                      setCurrentValue(getValues(AMOUNT_FIELD_NAME))
                    }}
                  />
                </div>
              )
            }}
          />
        )}
      </LayoutStack>
    </Fieldset>
  )
}

export default AmountSelection
