'use client'
import SubmitButton from './SubmitButton'
import { type IconButtonProps } from '@betterplace/design-system/client'
import { useActionFormContext } from '../ActionForm'
import { useIsMounted } from '@/helpers/hooks'
import type { FormSubmitButtonProps } from './types'
// eslint-disable-next-line import/exports-last
function FormSubmitButton({ busy: busy_, disabled_: disabled__, iconName, ...props }: FormSubmitButtonProps) {
  const {
    formState: { isSubmitting: isSubmitting_ },
    busy,
  } = useActionFormContext()
  const isSubmitting = isSubmitting_ || busy
  const disabled_ = !useIsMounted()
  return (
    <SubmitButton
      {...(props as IconButtonProps<'button'>)}
      busy={isSubmitting || busy_}
      disabled_={disabled_ || disabled__}
    />
  )
}

export default FormSubmitButton
