'use client'

import Field from '@/form/ActionForm/Field'
import type { FieldAsPropType, FieldProps } from '@/form/ActionForm/Field'
import type { FieldPath, FieldValues } from 'react-hook-form'

function RequiredField<
  TFormValues extends FieldValues,
  TField extends FieldPath<TFormValues>,
  TInput extends FieldAsPropType = 'input',
>(props: FieldProps<TFormValues, TField, TInput>) {
  return <Field {...props} label={`${props.title}*`} required />
}

export function WithFormValues<TFormValues extends FieldValues>() {
  return RequiredField as <TField extends FieldPath<TFormValues>, TInput extends FieldAsPropType = 'input'>(
    props: FieldProps<TFormValues, TField, TInput>
  ) => JSX.Element
}

export default RequiredField
