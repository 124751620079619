'use client'
import { Checkbox, type CheckboxProps } from '@betterplace/design-system/client'
import { forwardRef } from 'react'

import ErrorMessage from '../ErrorMessage'

const FormCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(function FormCheckbox(
  { children, ...props }: CheckboxProps,
  ref
) {
  const id = props.id ?? props.name
  return (
    <div className={props.className}>
      <Checkbox {...props} ref={ref} id={id}>
        {children}
      </Checkbox>
      <ErrorMessage name={props.name!} />
    </div>
  )
})

export default FormCheckbox
