'use client'

import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import FormCheckbox from '@/form/FormCheckbox'
import FormInput from '@/form/FormInput'
import FormTextArea from '@/form/FormTextArea'
import styles from './MessageField.module.css'
import { LayoutCluster, LayoutStack } from '@betterplace/design-system/client'
import { useDonationFormConfig, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function getFullName(firstName: string, lastName: string) {
  if (!firstName && !lastName) return ''
  if (!lastName) return firstName
  if (!firstName) return lastName
  return `${firstName} ${lastName.substring(0, 1) + '.'}`
}

function ShowUserLabel() {
  const t = useTranslations('nextjs.donate')
  const [companyDonation, companyName, firstName, lastName] = useDonationFormValues([
    '_company_donation',
    'company_name',
    'first_name',
    'last_name',
  ])
  const displayName = companyDonation ? (companyName ?? '') : getFullName(firstName, lastName)
  const trimmedDisplayName = displayName.length > 18 ? `${displayName.substring(0, 18)}...` : displayName
  if (!trimmedDisplayName) {
    return <>{t('show_user.label')}</>
  }
  return <>{`${t('show_user.label')}: ${trimmedDisplayName}`}</>
}

function MessageField() {
  const t = useTranslations('nextjs.donate')
  const { showDisplayName, showMessageField } = useDonationFormConfig()
  if (!showMessageField) return null
  return (
    <LayoutStack space="400">
      {showDisplayName && <DonationFormField name="display_name" as={FormInput} label={t('attributes.display_name')} />}

      <DonationFormField name="message" as={FormTextArea} label={t('attributes.message')} rows={1} />

      <LayoutCluster space="250" className={styles.checkboxes}>
        <DonationFormField name="show_amount" as={FormCheckbox}>
          {t('show_amount.label')}
        </DonationFormField>
        {!showDisplayName && (
          <DonationFormField name="show_user" as={FormCheckbox}>
            <ShowUserLabel />
          </DonationFormField>
        )}
      </LayoutCluster>
    </LayoutStack>
  )
}

export default MessageField
