/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form'
import { get } from 'lodash'
import { useActionFormContext } from '..'
import { useMemo } from 'react'
import type { FieldAsPropType, FieldProps } from './types'
import type { Operation } from '@/helpers/operations'

// Remember to provide the type prop to the Field component in case of using radio or checkbox inputs
function Field<
  TFields extends FieldValues,
  TField extends FieldPath<TFields>,
  TInput extends FieldAsPropType = 'input',
>({ name, as, ...props }: FieldProps<TFields, TField, TInput>) {
  const {
    onBlur,
    onChange,
    validate,
    valueAsDate,
    valueAsNumber,
    setValueAs,
    min,
    max,
    maxLength,
    minLength,
    pattern,
    required,
    shouldUnregister,
    ...inputProps
  } = props
  const { value } = inputProps
  const Tag: any = as ?? 'input'
  const { register, defaultValues } = useActionFormContext<TFields, Operation<any, any>>()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const defaultValue = useMemo(() => get(defaultValues, name), [defaultValues, name])
  const type = useMemo(
    () =>
      'type' in props
        ? (props.type as string | undefined)
        : typeof defaultValue === 'boolean' || Array.isArray(defaultValue)
          ? 'checkbox'
          : undefined,

    //@ts-expect-error props.type does not exist on all inputs assignable to the Field component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue, props.type]
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const defaultChecked = useMemo(() => {
    if (type !== 'checkbox' && type !== 'radio') return
    if (type === 'radio') return defaultValue === value
    if (typeof defaultValue === 'boolean' || !defaultValue) return !!defaultValue
    if (defaultValue === value) return true
    if (Array.isArray(defaultValue)) return (defaultValue as any[]).includes(value)
    return false
  }, [type, defaultValue, value])

  const defaultProps = useMemo(
    () =>
      type === 'checkbox' || type === 'radio'
        ? { defaultChecked }
        : defaultValue !== undefined
          ? { defaultValue }
          : undefined,
    [defaultChecked, defaultValue, type]
  )
  return (
    <Tag
      {...inputProps}
      {...register(name, {
        onBlur,
        onChange,
        validate,
        valueAsDate,
        valueAsNumber,
        setValueAs,
        min,
        max,
        maxLength,
        minLength,
        pattern,
        required,
        shouldUnregister,
      } as RegisterOptions<TFields, TField>)}
      min={min}
      max={max}
      {...defaultProps}
    />
  )
}

export function WithFormValues<TFormValues extends FieldValues>() {
  return Field as <TField extends FieldPath<TFormValues>, TInput extends FieldAsPropType = 'input'>(
    props: FieldProps<TFormValues, TField, TInput>
  ) => JSX.Element
}

export default Field
