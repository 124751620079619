'use client'
import DonationFormRequiredField from '@/donationForm/_dependencies/components/shared/DonationFormRequiredField'
import FormInput from '@/form/FormInput'
import { useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function CompanyFields() {
  const t = useTranslations('nextjs.donate.attributes')
  const [companyDonation] = useDonationFormValues(['_company_donation'])
  if (!companyDonation) return null

  return <DonationFormRequiredField name="company_name" title={t('company_name')} as={FormInput} />
}

export default CompanyFields
