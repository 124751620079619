import { numericString } from '@/schema'
import { z } from 'zod'

const votingFields = z.discriminatedUnion('_voting_opt_in', [
  z.object({
    _voting_opt_in: z.literal(true),
    voting_option_id: numericString,
  }),
  z.object({
    _voting_opt_in: z.literal(false),
    voting_option_id: numericString.or(z.null()).optional(),
  }),
])

export default votingFields
