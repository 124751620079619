'use client'

import Modal from '@/donationForm/_dependencies/components/shared/Modal'
import styles from './Terms.module.css'
import { Button } from '@betterplace/design-system/client'
import { PaymentMethods } from '@betterplace/api-graphql-types/enums'
import { postMessageScrollTop } from '@/helpers/utils'
import { useCallback, useState } from 'react'
import { useDonationFormConfig, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

const TermsLink = () => {
  const t = useTranslations('nextjs.donate.terms')
  return (
    <a target="_blank" className="larger-click-area" href={t('terms_url')} rel="noopener noreferrer">
      {t('terms_label')}
    </a>
  )
}

const PrivacyLink = () => {
  const t = useTranslations('nextjs.donate.terms')
  return (
    <a target="_blank" className="larger-click-area" href={t('privacy_url')} rel="noopener noreferrer">
      {t('privacy_label')}
    </a>
  )
}

function BetterplaceInfo() {
  const t = useTranslations('nextjs')
  const { channel } = useDonationFormConfig()
  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])
  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])
  const channelPart = channel === 'iframe' ? channel : 'default'

  return (
    <>
      {' '}
      <span>{t(`donate.terms.${channelPart}_recipient_info`)}</span>{' '}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="javascript:void(0)" onClick={openModal}>
        {t('donate.terms.default_recipient_name')}
      </a>{' '}
      <span>(betterplace.org gGmbH)</span>
      <Modal
        onClose={hideModal}
        id="betterplace-info-modal"
        onOpen={postMessageScrollTop}
        isOpen={showModal}
        title={t('donate.terms.recipient_info_modal_headline')}
        customActions={
          <Button kind="primary" size="small" onClick={hideModal} type="button" className={styles.closeModalButton}>
            {t('donate.codonation_selection.why_modal.close_button')}
          </Button>
        }
        closeText={t('core.close')}
      >
        <span>{t('donate.terms.recipient_info_modal_text')}</span>
        <br />
        <a href={t('donate.terms.gut_org_help_url')} target="_blank" rel="noopener noreferrer">
          {t('donate.terms.recipient_info_read_more_label')}
        </a>
      </Modal>
    </>
  )
}

function SEPAMandateLink() {
  const [paymentMethod] = useDonationFormValues(['payment_method'])

  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])
  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const t = useTranslations('nextjs')
  if (paymentMethod !== PaymentMethods.StripeSepaDebit) return null

  return (
    <>
      {' '}
      <span>{t('donate.terms.sepa_prefix')}</span>{' '}
      <button type="button" className="simulated-link larger-click-area" onClick={openModal}>
        {t('donate.terms.sepa_mandate_label')}
      </button>
      <Modal
        onClose={hideModal}
        onOpen={postMessageScrollTop}
        isOpen={showModal}
        title={t('donate.terms.sepa_mandate_label')}
        customActions={
          <Button kind="primary" size="small" onClick={hideModal} type="button" className={styles.closeModalButton}>
            {t('donate.codonation_selection.why_modal.close_button')}
          </Button>
        }
        closeText={t('core.close')}
      >
        {t('donate.terms.sepa_mandate_stripe_disclaimer')}
      </Modal>
    </>
  )
}

export default function Terms() {
  const t = useTranslations('nextjs.donate.terms')

  return (
    <div className={styles.termsOfUseContainer}>
      <span>{t('terms_link_prefix')}</span>
      <TermsLink />
      <span>{t('privacy_link_prefix')}</span>
      <PrivacyLink />
      <BetterplaceInfo />
      <SEPAMandateLink />
      <span>{t('terms_suffix')}</span>
    </div>
  )
}
