/* eslint-disable import/prefer-default-export */
import sanitize from 'sanitize-html'
import { unescape } from 'lodash'
import { z } from 'zod'
export function getRequiredCappedString(capAt: number) {
  return z.preprocess(
    (v) => (typeof v === 'number' ? String(v) : v),
    z
      .string()
      .min(1)
      .max(capAt)
      .transform((val) => unescape(sanitize(val)))
  )
}
export function getOptionalCappedString(capAt: number) {
  return z.preprocess(
    (v) => (typeof v === 'number' ? String(v) : v),
    z
      .string()
      .min(0)
      .max(capAt)
      .optional()
      .transform((val) => val && unescape(sanitize(val)))
  )
}

export const requiredCappedString255 = getRequiredCappedString(255)
export const cappedString255 = getOptionalCappedString(255)
export const cappedString64 = getOptionalCappedString(64)
export const requiredCappedString64 = getRequiredCappedString(64)
export const text = z.preprocess(
  (v) => (typeof v === 'number' ? String(v) : v),
  z.string().transform((val) => (typeof val === 'string' && val ? unescape(sanitize(val)) : val))
)
