import useFormErrorMessage from '../ActionForm/useFormErrorMessage'
import { TextInput, type TextInputProps } from '@betterplace/design-system/client'
import { forwardRef } from 'react'

const FormInput = forwardRef<HTMLInputElement, TextInputProps>(function FormInput(
  { className, ...props }: TextInputProps,
  ref
) {
  const name = props.name
  const error = useFormErrorMessage(name ?? '')
  return <TextInput {...props} type="text" ref={ref} className={className} error={error} />
})

export default FormInput
