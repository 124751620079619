import classNames from 'classnames'
import styles from './ErrorMessage.module.css'
import useFormErrorMessage from '../ActionForm/useFormErrorMessage'
import { ErrorMessageProps } from './types'
import type { FieldValues } from 'react-hook-form'

function ErrorMessage<TFormValues extends FieldValues>({ name, className }: ErrorMessageProps<TFormValues>) {
  const error = useFormErrorMessage<TFormValues>(name)
  return (
    <div className={classNames(styles.helpInline, className)} id={`${name}-error`}>
      {error}
    </div>
  )
}

export default ErrorMessage
