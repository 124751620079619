'use client'
import { Heading } from '@betterplace/design-system/client'
import { PaymentMethods } from '@betterplace/api-graphql-types/enums'
import { toLowerCase } from '@betterplace/utils'
import { useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function HolderTitle() {
  const [paymentMethod] = useDonationFormValues(['payment_method'])
  if (paymentMethod === PaymentMethods.StripeGiropay) throw new Error('Giropay is not supported in this context')
  const t = useTranslations('nextjs.donate')
  return <Heading level="h200">{t(`holder_title.${toLowerCase(paymentMethod)}`)}</Heading>
}

export default HolderTitle
