'use client'

import useFormErrorMessage from '../ActionForm/useFormErrorMessage'
import { type FieldValues } from 'react-hook-form'
import { FormTextareaProps } from './types'
import { Textarea } from '@betterplace/design-system/client'
import { forwardRef } from 'react'

const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(function FormTextArea(
  { className, ...props }: FormTextareaProps,
  ref
) {
  const name = props.name ?? ''
  const error = useFormErrorMessage<FieldValues>(name)
  return <Textarea {...props} ref={ref} error={error} />
})

export default FormTextArea
