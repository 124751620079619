import useActionFormContext from './useActionFormContext'
import { get } from 'lodash'
import type { FieldPath, FieldValues } from 'react-hook-form'

function useFormErrorMessage<TFieldValues extends FieldValues>(fieldName: FieldPath<TFieldValues>): string | undefined {
  const {
    formState: { errors, touchedFields },
  } = useActionFormContext<TFieldValues, unknown>()

  const touched = get(touchedFields, fieldName)
  const error = get(errors, fieldName)
  if (!touched || !error) return
  return error.message as string
}

export default useFormErrorMessage
