'use client'

import DonationFormField from '../../../shared/DonationFormField'
import FormCheckbox from '@/form/FormCheckbox'
import styles from './VotingQuestion.module.css'
import useFormErrorMessage from '@/form/ActionForm/useFormErrorMessage'
import { DonationFormValues } from '@/donationForm/types'
import { Fieldset, Heading, LayoutStack, RadioButton } from '@betterplace/design-system/client'
import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationForm/_dependencies/helpers'
import { useEffect } from 'react'
import { useTranslations } from 'next-intl'

function VotingQuestion() {
  const { question } = useDonationFormConfig()
  if (!question) return null
  const { options } = question
  return <VotingQuestionContent {...question} options={options} />
}
type Option = { id: string; text: string }
type VotingQuestionContentProps = { text: string; options: Array<Option> }

function VotingQuestionContent({ text, options }: VotingQuestionContentProps) {
  const t = useTranslations('nextjs.donate.voting')
  const [optIn] = useDonationFormValues(['_voting_opt_in'])
  const { clearErrors } = useDonationFormContext()
  const error = useFormErrorMessage<DonationFormValues>('voting_option_id')
  useEffect(() => {
    if (optIn) return
    clearErrors('voting_option_id')
  }, [clearErrors, optIn])
  return (
    <LayoutStack>
      <Fieldset
        className={styles.fieldset}
        error={optIn ? error : undefined}
        legend={
          <DonationFormField as={FormCheckbox} name="_voting_opt_in">
            <Heading level="h200" className={styles.headingMargin}>
              {t('heading')}
            </Heading>
          </DonationFormField>
        }
        layout="vertical"
      >
        {optIn && (
          <>
            <Heading level="h300">{text}</Heading>
            {options.map(({ id, text }) => (
              <DonationFormField key={id} as={RadioButton} type="radio" name="voting_option_id" value={id}>
                {text}
              </DonationFormField>
            ))}
          </>
        )}
      </Fieldset>
    </LayoutStack>
  )
}

export default VotingQuestion
