import FormInput from '@/form/FormInput'
import useRequiredComponent from './useRequiredComponent'
import { NumericZipCountryCodes } from '@/schema'
import { useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'
import type { ZipFieldProps } from './types'

function ZipField({ onBlur, required }: ZipFieldProps) {
  const FieldComponent = useRequiredComponent(required)
  const [countryAlpha2] = useDonationFormValues(['country_alpha2'])
  const numericZip = !!NumericZipCountryCodes[countryAlpha2 as keyof typeof NumericZipCountryCodes]
  const t = useTranslations('nextjs.donate.attributes')
  const label = t('zip')
  return (
    <FieldComponent name="zip" title={label} as={FormInput} inputMode={numericZip ? 'tel' : 'text'} onBlur={onBlur} />
  )
}

export default ZipField
